<template>
    <v-card class="card d-flex flex-column justify-space-between card-height" elevation="0">
        <v-card-title class="main_color_text font-weight-bold title-card">
            <router-link :to="data_info_block.linkHeader" class="text-decoration-none main_color_text">
                {{ data_info_block.title }}
            </router-link>
        </v-card-title>
        <v-card-text>
            <p class="subtitle-1 description font-weight-medium">{{ data_info_block.description }}</p>
        </v-card-text>
        <v-card-actions>
            <router-link :to="data_info_block.linkFooter" class="text-decoration-none main_color_text">
                {{ data_info_block.linkTitle }}
            </router-link>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    name: "InfoBlockHomePage",
    props: ['data_info_block'],
    data: function () {
        return {

        }
    },
}
</script>

<style lang="scss" scoped>
.card {
    height: 238px;
}

p.description {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    max-width: 295px;
    color: #000;
}

@media all and (max-width: 1240px) {
    .card-height {
        height: 230px;
    }
}

.title-card {
    word-break: break-word;
    font: 700 19px/21px "PT Sans",Arial,"Helvetica Neue",Helvetica,sans-serif !important;
    height: 53px;
}
</style>
